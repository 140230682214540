import React from 'react'
import PostPreview from './PostPreview'
import { Col, Container, Row } from 'reactstrap'

const PostsContainer = ({ children, posts }) => {

  const renderPosts = posts => {
    return posts.map((post, index) => (
      <PostPreview
        key={index}
        title={post.title}
        excerpt={post.excerpt}
        author={post.author}
        date={post.date}
        slug={post.slug}
        imageUrl={post.imageUrl}
      />
    ))
  }

  return (
    <div>
      <Row>
        <Col className="">
          {renderPosts(posts)}
        </Col>
      </Row>
      <Row>
        <Col className="">
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default PostsContainer
