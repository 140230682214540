import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PostsContainer from '../components/PostsContainer'

class BlogIndex extends React.Component {
  //TODO replace with env var
  POSTS_PER_PAGE = 5

  render() {
    const { data } = this.props
    const intro = data.wordpressPage
    const posts = data.allWordpressPost.edges
    const hasNextPage = posts.length > this.POSTS_PER_PAGE

    return (
      <Layout>
        <SEO
          title={intro.yoast.title}
          description={intro.yoast.metadesc}
        />
        <PostsContainer
          posts={posts.slice(0, this.POSTS_PER_PAGE).map(({ node }) => ({
            slug: node.slug,
            title: node.title,
            excerpt: node.excerpt,
            author: node.author.name,
            date: node.date,
            imageUrl: getImageUrl(node)
          }))}
        >
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: '0',
            }}
          >
            <li />
            <li>
              {hasNextPage && (
                <Link className="button w-button" to={'/page-2'} rel="next">
                  Older posts →
                </Link>
              )}
            </li>
          </ul>
        </PostsContainer>
      </Layout>
    )
  }
}

export default BlogIndex

const getImageUrl = (node) => {
  try {
    return node.featured_media.localFile.childImageSharp.original.src
  } catch (e) {
    return null
  }
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      ...Page
    }
    allWordpressPost(sort: { order: DESC, fields: date }, limit: 6) {
      edges {
        node {
          ...Post
        }
      }
    }
  }
`
