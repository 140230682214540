import React from 'react'
import moment from 'moment'
import { replaceTag } from 'utils/Parser.js'
import { Link } from 'gatsby'

const PostPreview = ({ slug, title, excerpt, author, date, key, imageUrl }) => {
  return (
    <div key={key} className="w-dyn-item">
      <div className="post-wrapper">
        {imageUrl && (
          <Link
            to={`/${slug}`}
            className="blog-image w-inline-block"
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        )}
        <div className="post-content">
          <Link to={`/${slug}`} className="blog-title-link w-inline-block">
            <h1 className="blog-title">{title}</h1>
          </Link>
          <div className="post-summary-wrapper">
            <p className="post-summary">{replaceTag(excerpt, 'p', null)}</p>
            <Link className="read-more-link" to={`/${slug}`}>
              Read more...
            </Link>
          </div>
          <div className="post-info-wrapper">
            <div className="post-info">
              {`Posted by ${author} on ${moment(date).format('LL')}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostPreview
